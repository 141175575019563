import ResponsiveImage from '../../components/ResponsiveImage/ResponsiveImage';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config';
import styles from './KeyVisual.module.scss';

/**
 * Main template
 * Renders a single image with an optional caption.
 */
export default ({ image, caption }) => {
  return (
    <>
      <ResponsiveImage className={styles['key-visual']} image={image} />
      {caption && <div className={styles.caption}>{RichText.render(caption, prismicConfig.linkResolver)}</div>}
    </>
  );
};
