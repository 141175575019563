import React, { useRef } from 'react';
import LazyLoad from 'react-lazyload';
import { CSSTransition } from 'react-transition-group';
import './WithLazyFade.css';

export default (WrappedComponent) => {
  return function WithLazyFade(props) {
    const nodeRef = useRef(null);
    return (
      <LazyLoad height={props.height} offset={props.offset} unmountIfInvisible={props.unmountIfInvisible}>
        <CSSTransition
          nodeRef={nodeRef}
          enter={false}
          exit={false}
          appear={true}
          in={true}
          timeout={1000}
          classNames="kq-lazyfade-"
        >
          <div ref={nodeRef}>
            <WrappedComponent {...props} />
          </div>
        </CSSTransition>
      </LazyLoad>
    );
  };
};
