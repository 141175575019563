import React from 'react';
import Stripe from '../Stripe/Stripe';
import pkg from '../../../package.json';
import styles from './Footer.module.scss';

export default (doc) => {
  return (
    <Stripe className={styles.footer}>
      <span className={styles.version}>v{pkg.version}</span>
      <div className={styles.logos}>
        {doc?.data.supporters.map((supporter) => (
          <a
            key={supporter.name}
            className={styles.link}
            href={supporter.website}
            target="_blank"
            rel="noreferrer noopener"
          >
            <div
              className={styles.logo}
              style={{
                backgroundImage: `url(${supporter.logotype.url})`,
                height: `${supporter.logotype_scale}%`,
              }}
            />
          </a>
        ))}
      </div>{' '}
    </Stripe>
  );
};
