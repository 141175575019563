import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import Moment from 'moment';
import { prismicConfig } from '../../config';
import Placeholder from '../../components/Placeholder/Placeholder';
import KeyVisual from '../../components/KeyVisual/KeyVisual';
import RessourceGroup from '../../components/RessourceGroup/RessourceGroup';
import ButtonBack from '../../components/ButtonBack/ButtonBack';
import Stripe from '../../layout/Stripe/Stripe';
import styles from './Event.module.scss';

/**
 * Main template
 */
export default (doc) => (
  <Stripe>
    {doc ? article(doc.data) : <Placeholder title={{ lines: 2, size: 'xl' }} teaser={{ lines: 5, size: 'l' }} />}
  </Stripe>
);

/**
 * Article template
 */
const article = ({ title, teaser, text, key_image, key_image_credits, ressources, start_time, end_time, location }) => (
  <article className={styles.event}>
    <Link to="/" className={styles.back}>
      <ButtonBack label="Zur Übersicht" />
    </Link>
    <KeyVisual image={key_image} caption={key_image_credits} />
    <h1 className={styles.title}>{title ? RichText.asText(title) : null}</h1>
    <div className={styles.teaser}>{teaser ? RichText.render(teaser, prismicConfig.linkResolver) : null}</div>
    <div className={styles.meta}>
      {start_time ? (
        <time className={styles.time} dateTime={start_time}>
          {Moment(start_time).format('Do MMMM YYYY, H:mm')}
          {end_time ? ` – ${Moment(end_time).format('H:mm')}` : null}
        </time>
      ) : null}
      {location ? <div className={styles.location}>{RichText.render(location, prismicConfig.linkResolver)}</div> : null}
    </div>
    <div className={styles.richtext}>{text ? RichText.render(text, prismicConfig.linkResolver) : null}</div>
    <div className={styles.ressources}>
      {ressources.map((group, index) => (
        <RessourceGroup group={group} index={index} key={index} />
      ))}
    </div>
  </article>
);
