import React from 'react';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config.js';
import styles from './Quote.module.scss';

const tmpl = ({ uid, text, author_name, author_title, author_portrait, display }) => {
  return (
    <blockquote
      className={`${styles.quote} kq-collection__item kq-collection__item--quote ${
        display === 'Highlighted' ? 'kq-collection__item--highlighted' : ''
      }`}
    >
      <div className={styles['author-portrait']} style={{ backgroundImage: `url(${author_portrait.url})` }} />
      <p className={styles.text}>{text ? RichText.asText(text, prismicConfig.linkResolver).trim() : null}</p>
      <p className={styles.author}>
        <span className={styles['author-name']}>
          {author_name ? RichText.asText(author_name, prismicConfig.linkResolver) : null}
          {author_title ? ', ' : ''}
        </span>
        <span className={styles['author-title']}>
          {author_title ? RichText.asText(author_title, prismicConfig.linkResolver) : null}
        </span>
      </p>
    </blockquote>
  );
};

export default tmpl;
