import React from 'react';
import RessourceLink from '../../components/RessourceLink/RessourceLink';
import styles from './RessourceGroup.module.scss';

export default ({ group, index }) => {
  return (
    <ul key={index}>
      {group.items.map((item, i) => {
        return (
          <li className={styles.ressource} key={i}>
            <RessourceLink item={item} />
          </li>
        );
      })}
    </ul>
  );
};
