import React from 'react';
import Stripe from '../../layout/Stripe/Stripe';
import NewsletterForm from '../../components/NewsletterForm/NewsletterForm';

export default () => {
  return (
    <Stripe>
      <h1 className="kq-intro__title">Newsletter</h1>
      <NewsletterForm />
    </Stripe>
  );
};
