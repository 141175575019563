import React from 'react';
import { Link } from 'react-router-dom';
import Stripe from '../../layout/Stripe/Stripe';
import styles from './NotFound.module.scss';

export default () => (
  <Stripe>
    <h1 className={styles.title}>Oops… diese Seite gibt es nicht.</h1>
    <div className={styles.richtext}>
      <p>
        <Link to="/">Hier geht es zur Startseite.</Link>
      </p>
    </div>
  </Stripe>
);
