import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { raenv } from '../../helpers';
import styles from './NewsletterForm.module.scss';

export default () => (
  <MailchimpSubscribe
    url={raenv('MAILCHIMP_FORMURL')}
    render={({ subscribe, status, message }) => {
      return (
        <div>
          <SubscribeForm status={status} message={message} onValidated={(formData) => subscribe(formData)} />
          {status ? messageTmpl(status === 'sending' ? 'Einen Moment bitte…' : message, status) : null}
        </div>
      );
    }}
  />
);

const messageTmpl = (message, status) => (
  <div
    className={`${styles.message} ${status === 'error' ? styles['message--error'] : ''}`}
    dangerouslySetInnerHTML={{ __html: message }}
  />
);

const SubscribeForm = ({ status, message, onValidated }) => {
  let email;
  const submit = (event) => {
    event.preventDefault();
    email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  };

  return (
    <form onSubmit={submit}>
      <div className={styles.group}>
        <input
          type="email"
          ref={(node) => (email = node)}
          required
          defaultValue={email}
          className={`${styles.input} ${styles['input--email']}`}
          placeholder="lotte@home.ch"
        />
        <input type="submit" className={`${styles.input} ${styles['input--submit']}`} value="Anmelden" />
      </div>
    </form>
  );
};
