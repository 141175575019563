import React, { useRef } from 'react';
import { applyTenantTheme, raenv } from '../../helpers';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import prismicConfig from '../../config/prismic.config';
import classNames from 'classnames';
import styles from './Nav.module.scss';
import stylesKochquartier from './Nav.kochquartier.module.scss';
import stylesTransition from './Nav.transition.module.scss';

const props = {
  hideNav: null,
  isNavActive: null,
};

/**
 * Template: Main
 */
export default (hideNav, isNavActive, doc, className) => {
  // Make props available for helper functions
  props.hideNav = hideNav;
  props.isNavActive = isNavActive;

  const nodeRef = useRef(null);
  const hostClass = classNames(styles.nav, className);
  return (
    <CSSTransition nodeRef={nodeRef} in={isNavActive} timeout={300} classNames={{ ...stylesTransition }}>
      <nav className={hostClass} key="nav" ref={nodeRef}>
        <div className={styles.wrapper}>
          {doc ? doc.data.nav.map(section) : <h1>Loading...</h1>}
          {raenv('TENANT') === 'kochquartier' && (
            <div className={stylesKochquartier['map-frame']}>
              <div className={stylesKochquartier.map}></div>
            </div>
          )}
        </div>
      </nav>
    </CSSTransition>
  );
};

/**
 * Template: Navigation section
 */
const section = (section, i) => (
  <ul className={styles.section} key={`section-${i}`}>
    {/* Add the section title */}
    <h1 className={styles.title}>{section ? RichText.asText(section.primary.label) : null}</h1>
    {section.items.map(({ link, label }) => item(link, label))}
  </ul>
);

/**
 * Helper: Extract path from Prismic link object
 */
const getLinkPath = (link) => {
  switch (link.link_type) {
    case 'Web':
      // Web links format: <protocol>://<domain>/<path>
      // Thus everything after the first three '/' is the path
      return `/${link.url.split('/').splice(3).join('/')}`;
    case 'Document':
      return prismicConfig.linkResolver(link);
    default:
      return null;
  }
};

/**
 * Template: Navigation section item
 */
const item = (link, label) => {
  const path = getLinkPath(link);
  if (!path) {
    return null;
  }
  return (
    <li className={styles.item} key={`item-${link.uid}`}>
      <Link to={path} className={styles.link} onClick={props.hideNav}>
        {label ? RichText.asText(label) : null}
      </Link>
    </li>
  );
};
