import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import * as prismic from '@prismicio/client';
import prismicConfig from '../../config/prismic.config';

const client = prismic.createClient(prismicConfig.apiEndpoint);

export default () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const resolvePreview = async () => {
      const { token } = qs.parse(location.search.slice(1));
      if (!token) {
        return console.warn(`No token available, check your configuration`);
      }
      const url = await client.resolvePreviewURL({
        linkResolver: prismicConfig.linkResolver,
        defaultURL: '/',
      });
      url && navigate(url);
    };
    resolvePreview();
  }, []);
  return null;
};
