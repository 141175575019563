import React from 'react';
import './Placeholder.scss';

const lines = (linesNum, size = 'm') => {
  const lines = [];
  for (let i = 0; i < linesNum; i++) {
    lines.push(<span key={i} className={`kq-placeholder__line kq-placeholder__line--${size}`} />);
  }
  return lines;
};

export default ({ title, teaser }) => {
  return (
    <div className="kq-placeholder">
      {title ? <div className="kq-placeholder__title">{lines(title.lines, title.size)}</div> : null}
      {teaser ? <div className="kq-placeholder__teaser">{lines(teaser.lines, teaser.size)}</div> : null}
    </div>
  );
};
