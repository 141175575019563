import React from 'react';
import styles from './Stripe.module.scss';

export default ({ children, className }) => {
  return (
    <div className={[styles.host, className].filter(Boolean).join(' ')}>
      <div className={styles.layout}>
        <div className={styles.inner}>{children}</div>
      </div>
    </div>
  );
};
