import React from 'react';
import styles from './ResponsiveImage.module.scss';

export default ({ image, className }) => {
  if (image && image.url) {
    return (
      <picture className={`${styles['responsive-image']} ${className}`}>
        {image.xs ? <source media="(max-width: 448px)" srcSet={image.xs.url} /> : null}
        {image.s ? <source media="(max-width: 848px)" srcSet={image.s.url} /> : null}
        <source srcSet={image.url} />
        <img src={image.url} alt={image.alt} />
      </picture>
    );
  }
  return null;
};
