import React from 'react';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config.js';
import Placeholder from '../../components/Placeholder/Placeholder';
import NewsletterForm from '../../components/NewsletterForm/NewsletterForm';
import styles from './Intro.module.scss';

export default (doc) => {
  if (doc) {
    const { title, text } = doc.data;
    return (
      <div>
        <span className="kq-landing__section-title">Intro</span>
        <h1 className={styles.title}>{title ? RichText.asText(title) : null}</h1>
        <div className={styles.richtext}>{text ? RichText.render(text, prismicConfig.linkResolver) : null}</div>
        <NewsletterForm />
      </div>
    );
  }
  return <Placeholder title={{ lines: 2, size: 'xl' }} teaser={{ lines: 6, size: 'm' }} />;
};
