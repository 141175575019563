import React from 'react';
import Intro from '../../layout/Intro/Intro';
import Collection from '../../layout/Collection/Collection';
import Stripe from '../../layout/Stripe/Stripe';
import './Landing.scss';

export default () => {
  return (
    <Stripe>
      <Intro />
      <Collection />
    </Stripe>
  );
};
