import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import withLazyFade from '../../hoc/WithLazyFade/WithLazyFade';
import BlogPostTeaser from '../../layout/BlogPostTeaser/BlogPostTeaser';
import EventTeaser from '../../layout/EventTeaser/EventTeaser';
import Quote from '../../layout/Quote/Quote';
import Loader from '../../components/Loader/Loader';
import './Collection.scss';

export default (docs, loadFunc, hasMorePosts) => {
  const posts = [];
  posts.push(docs.map(post));
  return (
    <div className="kq-collection">
      <InfiniteScroll
        pageStart={0}
        loadMore={loadFunc}
        hasMore={hasMorePosts}
        loader={<Loader key="loader" minHeight="300px" />}
      >
        {posts}
      </InfiniteScroll>
    </div>
  );
};

const post = (doc) => {
  const { id, uid, type } = doc;
  let comp = null;
  // Event
  if (type === 'event') {
    comp = {
      obj: EventTeaser,
      props: {
        key: `item-${id}`,
        id,
        uid,
        ...doc.data,
      },
    };
  }
  // Blog Post
  if (type === 'blog_post') {
    comp = {
      obj: BlogPostTeaser,
      props: {
        key: `item-${id}`,
        id: id,
        uid: uid,
        ...doc.data,
      },
    };
  }
  // Quote
  if (type === 'quote') {
    comp = {
      obj: Quote,
      props: {
        key: `item-${id}`,
        id: id,
        uid: uid,
        ...doc.data,
      },
    };
  }
  if (comp) {
    const ItemWithLazyFade = withLazyFade(comp.obj);
    return (
      <ItemWithLazyFade
        {...comp.props}
        key={comp.props.key}
        height={450}
        offset={[-50, 0]}
        unmountIfInvisible={false}
      />
    );
  }
};
