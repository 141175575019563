import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config';
import Placeholder from '../../components/Placeholder/Placeholder';
import RessourceGroup from '../../components/RessourceGroup/RessourceGroup';
import IFrame from '../../components/IFrame/IFrame';
import KeyVisual from '../../components/KeyVisual/KeyVisual';
import Carousel from '../../components/Carousel/Carousel';
import ButtonBack from '../../components/ButtonBack/ButtonBack';
import Stripe from '../../layout/Stripe/Stripe';
import styles from './BlogPost.module.scss';

/**
 * Main template
 */
export default (doc) => {
  return (
    <Stripe>
      {doc ? article(doc.data) : <Placeholder title={{ lines: 2, size: 'xl' }} teaser={{ lines: 5, size: 'l' }} />}
    </Stripe>
  );
};

/**
 * Article template
 */
const article = ({ title, teaser, text, key_image, key_image_credits, slides, ressources }) => {
  // Filter for slices rendered via component `RessourceGroup`
  const ressourceGroupItems = ressources.filter((item) => item.slice_type === 'link' || item.slice_type === 'media');
  // Filter form slices rendered via component `IFrame`
  const iFrameItems = ressources.filter((item) => item.slice_type === 'iframe');

  // Return template
  return (
    <article className={styles['blog-post']}>
      <Link to="/" className={styles.back}>
        <ButtonBack label="Zur Übersicht" />
      </Link>
      {slides?.length > 0 ? <Carousel slides={slides} /> : <KeyVisual image={key_image} caption={key_image_credits} />}
      <h1 className={styles.title}>{title ? RichText.asText(title) : null}</h1>
      <div className={styles.teaser}>{teaser ? RichText.render(teaser, prismicConfig.linkResolver) : null}</div>
      {iFrameItems.length > 0 && iFrameItems.map((item, index) => <IFrame {...item} key={index} />)}
      <div className={styles.richtext}>
        {text ? RichText.render(text, prismicConfig.linkResolver, prismicConfig.htmlSerializer) : null}
      </div>
      <div className={styles.ressources}>
        {ressourceGroupItems.map((group, index) => (
          <RessourceGroup group={group} index={index} key={index} />
        ))}
      </div>
    </article>
  );
};
